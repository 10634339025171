<template>
    <div class="anchor-container">
      <keep-alive>
      <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
    </div>
</template>

<script>

export default {
  name: "anchor-manager",
  data() {
    return {

    };
  },
  computed: {
  },
  mounted(){

  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.anchor-container {
  width: 100%;

}
</style>
